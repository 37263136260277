import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
} from '@angular/core';

import { BadgeComponent } from '../badge/badge.component';
import { IconComponent } from '../icon/icon.component';
import { LabelComponent } from '../label/label.component';

@Component({
    selector: 'eb-sidebar-menu-item',
    templateUrl: './sidebar-menu-item.component.html',
    styleUrls: ['./sidebar-menu-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        IconComponent,
        BadgeComponent,
        LabelComponent,
    ],
})
export class SidebarMenuItemComponent {
    @Input()
    public label: string = '';

    @HostBinding('class.icon')
    @Input()
    public icon?: string = undefined;

    @Input()
    public notifications?: number = 0;

    @Input()
    public description?: string;

    @HostBinding('class.selected')
    @Input()
    public selected?: boolean = false;

    @HostBinding('class.expanded')
    @Input()
    public expanded: boolean = false;

    @HostBinding('class.disabled')
    @Input()
    public disabled?: boolean = false;

    public constructor(
        public elementRef: ElementRef<HTMLElement>,
    ) { }
}
