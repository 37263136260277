<div class="valid-phone-number__header">
    <div class="valid-phone-number__header-left">
        <eb-h2 class="m-0 mb-2">
            {{ 'valid_account.verification_required' | transloco }}
        </eb-h2>
        @if (!smsSent) {
            <eb-label size="lg">
                {{ 'valid_account.ask_phone_number' | transloco }}
            </eb-label>
        }
        @if (smsSent) {
            <eb-label size="lg">
                {{ 'valid_account.enter_number' | transloco: { number: fcPhoneNumber.value } }}
            </eb-label>
        }
    </div>
    <div class="valid-phone-number__header-right">
        <eb-icon
            [width]="88"
            icon="ui/common/shield_check"
        />
    </div>
</div>
<div class="valid-phone-number__body">
    @if (error) {
        <eb-alert
            type="error"
            class="mb-3 w-100"
            [text]="errorMsg"
            size="small"
        />
    }
    @if (success) {
        <eb-alert
            type="success"
            class="mb-3 w-100"
            [text]="'valid_account.success' | transloco"
            size="small"
        />
    }

    @if (!smsSent) {
        <eb-material-input-phone-number
            class="w-100"
            [formControl]="fcPhoneNumber"
            [label]="'common.phone' | transloco"
            defaultCountry="FR"
        />
    }

    @if (smsSent && !success) {
        <div class="valid-phone-number__body-input-container">
            <input
                class="valid-phone-number__body-input"
                [formControl]="fcCode"
                [inputMask]="maskInput"
            />
        </div>
        <eb-label class="mt-3"
            >{{ 'valid_account.no_receive_code' | transloco }}
            <eb-label
                class="cursor-pointer"
                (click)="sendCode()"
                color="primary"
                >{{ 'valid_account.resend_code' | transloco }}</eb-label
            ></eb-label
        >
    }
</div>
<div class="valid-phone-number__footer">
    <div class="text-center">
        @if (smsSent) {
            <button
                eb-button
                class="mr-3"
                [outline]="true"
                (click)="back()"
                color="secondary"
                size="small"
            >
                {{ 'common.back' | transloco }}
            </button>
            <button
                eb-button
                [disabled]="loading || fcCode.invalid"
                [isLoading]="loading"
                [outline]="false"
                (click)="validCode()"
                color="primary"
                size="small"
            >
                {{ 'valid_account.confirm_code' | transloco }}
            </button>
        } @else {
            <button
                eb-button
                [disabled]="loading || fcPhoneNumber.invalid"
                [isLoading]="loading"
                [outline]="false"
                (click)="sendCode()"
                color="primary"
                size="small"
            >
                {{ 'valid_account.secure_my_account' | transloco }}
            </button>
        }
    </div>
    @if (sessionCount === 2) {
        <eb-label
            class="mt-4"
            size="lg"
        >
            {{ 'valid_account.compulsory_information' | transloco }}
        </eb-label>
    }
</div>
