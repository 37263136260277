import { CdkScrollable } from '@angular/cdk/scrolling';
import {
    AsyncPipe,
    DOCUMENT,
    NgClass,
} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
    ActivatedRoute,
    Event,
    NavigationEnd,
    Router,
    RouterOutlet,
} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import { differenceInDays } from 'date-fns';
import {
    BehaviorSubject,
    combineLatest,
    distinctUntilChanged,
    filter,
    interval,
    Observable,
    switchMap,
    timer,
} from 'rxjs';
import {
    map,
    startWith,
    tap,
} from 'rxjs/operators';

import {
    ModalLockedFeatureMode,
} from '~/app/authorisation/presentation/modal-locked-feature/modal-locked-feature.component';
import {
    ModalOnboardingNewMemberComponent,
} from '~/app/authorisation/presentation/modal-onboarding-new-member/modal-onboarding-new-member.component';
import { ResumeFeatureService } from '~/app/authorisation/services/resume-feature/resume-feature.service';
import { UNLOCKED_FEATURE_OPERATION_ID } from '~/app/core/constants/operation-resume-unlocked-feature.constants';
import { PERMISSIONS } from '~/app/core/constants/permissions.constants';
import { ValidPhoneNumberComponent } from '~/app/core/modals/valid-phone-number/valid-phone-number.component';
import { AuthenticationService } from '~/app/core/services/api/authentication/authentication.service';
import { ApplicationFacade } from '~/app/core/state/application/application-facade/application.facade';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AuthorisationFacade } from '~/app/core/state/authorisation/authorisation.facade';
import { ConfigurationFacade } from '~/app/core/state/configuration/configuration.facade';
import { SidebarComponent } from '~/app/shared/components/sidebar/sidebar.component';
import { ScrollDetectionDirective } from '~/app/shared/directives/scroll-detection/scroll-detection.directive';
import { FeatureFlag } from '~/app/shared/enums/feature-flag.enum';
import { RESUME_FEATURES } from '~/app/shared/enums/resume-features.enum';
import { ModalRef } from '~/app/shared/services/modal/modal-ref';
import { ModalService } from '~/app/shared/services/modal/modal.service';
import { Menu } from '~/app/shared/types/api/menu.type';
import { SidebarItemAuthorisation } from '~/app/shared/types/sidebar-item-authorisation.type';
import { SidebarItem } from '~/app/shared/types/sidebar-item.type';
import { User } from '~/app/shared/types/user/user.type';


@UntilDestroy()
@Component({
    selector: 'eb-main-layout',
    templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SidebarComponent,
        MatSidenavModule,
        ScrollDetectionDirective,
        CdkScrollable,
        RouterOutlet,
        AsyncPipe,
        NgClass,
    ],
})
export class MainLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    public expanded: boolean = true;

    public scrollDetectionEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public moduleChanged$ = this.router.events
        .pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
            startWith(this.router),
            map((event) => event.url.split('/').filter((value) => !!value)?.[0]),
            distinctUntilChanged(),
        );

    public mainItems$: Observable<SidebarItem[]> = combineLatest([
        this.transloco.selectTranslateObject('common.sidebar'),
        this.moduleChanged$,
    ]).pipe(
        map(([translations, module]: [Record<string, string>, string]) => [
            {
                key: 'funds',
                label: translations.funds,
                icon: 'ui/nav/funds',
                selected: module === 'shares',
                action: () => void this.router.navigateByUrl('shares/list'),
                permissions: [],
            },
            ...(
                this.configurationFacade.getFeatureFlagByFeatureFlagNameSnapshot(
                    FeatureFlag.TECH_FRONT_PORTFOLIO_ENABLE,
                )
                    ? [
                        {
                            key: 'portfolios',
                            label: translations.portfolios,
                            icon: 'ui/nav/portfolios',
                            selected: module === 'portfolios',
                            permissions: [PERMISSIONS.PORTFOLIO_MODULE_ACCESS],
                            resumeFeature: RESUME_FEATURES.PORTFOLIO_MODULE,
                            action: () => void this.router.navigateByUrl('portfolios'),
                        },
                    ] as SidebarItemAuthorisation[]
                    : []
            ),
            {
                key: 'contacts',
                label: translations.contacts,
                icon: 'ui/nav/contact',
                selected: module === 'contacts',
                permissions: [PERMISSIONS.CONTACT_MANAGE_ACCESS],
                resumeFeature: RESUME_FEATURES.CONTACT_MODULE,
                action: () => void this.router.navigateByUrl('contacts/list'),
            },
        ]),
    );

    public userItem$: Observable<SidebarItem> = combineLatest([
        this.transloco.selectTranslateObject('common.sidebar').pipe(startWith({})),
        this.authFacade.user$,
        this.moduleChanged$,
    ]).pipe(
        map(([translations, user]: [Record<string, string>, User | undefined, string]) => ({
            key: 'user',
            label: user ? `${user.firstName} ${user.lastName}` : translations.signin,
            icon: user ? 'ui/nav/user-params' : 'ui/nav/user',
            permissions: [],
            selected: this.router.url.includes('profile'),
            ...(!user ? { action: () => void this.router.navigateByUrl('auth/login') } : {}),
            items: user ? [
                {
                    key: 'user_profile',
                    label: translations.profile,
                    permissions: [],
                    icon: 'ui/nav/user',
                    action: () => void this.router.navigateByUrl('profile'),
                },
                {
                    key: 'user_logout',
                    label: translations.logout,
                    permissions: [],
                    icon: 'ui/nav/user',
                    action: () => {
                        void this.router.navigateByUrl('auth/logout', { state: { bypassGuardAndResolver: true } });
                    },
                },
            ] : [],
        })),
    );

    public otherItems$: Observable<SidebarItem[]> = this.applicationFacade.menu$.pipe(
        map((menus: Menu[]) => menus.map((menu, index) => (
            {
                key: `menu-${index}`,
                label: menu.name,
                permissions: [],
                ...(menu.url ? { action: () => this.openUrl(menu.url) } : {}),
                items: menu.children.length ? menu.children.map((submenu, subindex) => (
                    {
                        key: `menu-children-${subindex}`,
                        label: submenu.name,
                        permissions: [],
                        ...(submenu.url ? { action: () => this.openUrl(submenu.url) } : {}),
                    })) : [],
            }))),
    );

    public isSidebarOpen = false;

    public isLoading = true;

    public expandHeaderUrls = [
        '/shares/list',
        '/portfolios',
        '/contacts/list',
    ];

    private modalRef: ModalRef | null = null;


    constructor(
        private router: Router,
        private transloco: TranslocoService,
        private activatedRoute: ActivatedRoute,
        private resumeFeatureService: ResumeFeatureService,
        private applicationFacade: ApplicationFacade,
        private authenticationService: AuthenticationService,
        public configurationFacade: ConfigurationFacade,
        public authFacade: AuthenticationFacade,
        public authorisationFacade: AuthorisationFacade,
        @Inject(DOCUMENT) private document: Document,
        private modal: ModalService,
    ) {
    }

    ngOnInit() {
        this.applicationFacade.getMenus();
        if (this.authFacade.getUserSnapshot()?.id) {
            this.authFacade.refreshUser();
        }

        this.router.events.pipe(
            filter((e: any) => e instanceof NavigationEnd),
            distinctUntilChanged(),
            tap((event) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                if (this.expandHeaderUrls.includes(event.url)) {
                    this.scrollDetectionEnabled$.next(false);
                } else {
                    this.scrollDetectionEnabled$.next(true);
                }
            }), untilDestroyed(this),
        ).subscribe();

        combineLatest([
            this.authFacade.user$,
            this.applicationFacade.lastCheckSessionDate$,
            this.configurationFacade.phoneVerificationRequired$,
            interval(1000 * 60 * 60 * 2).pipe(
                startWith(1),
            ), // 2h
        ]).pipe(
            filter(([, , phoneRequired]: [User | undefined, string | null, boolean, number]) => phoneRequired),
            filter(([user]: [User | undefined, string | null, boolean, number]) => !!user),
            filter(([user]) => !user?.phoneVerified),
            filter(([, lastCheckDate]) => lastCheckDate === null
                    || differenceInDays(new Date(), new Date(lastCheckDate)) !== 0),
            switchMap(() => this.applicationFacade.updateLastCheckSessionDate(new Date().toISOString())),
            switchMap(() => this.authenticationService.user()),
            filter((user) => user.sessionCount > 1),
            untilDestroyed(this),
        ).subscribe((user) => {
            this.authFacade.updateUser(user);
            this.validPhoneNumber(user.sessionCount);
        });


        const user = this.authFacade.getUserSnapshot();
        if (user && user?.sessionCount > 2) {
            // To force to review modal if user refresh the page
            this.applicationFacade.updateLastCheckSessionDate(null);
        }

        // Reload licence when plan is revoked
        this.authorisationFacade.endValidity$
            .pipe(
                filter((endDate): endDate is string => !!endDate),
                filter((endDate) => differenceInDays(new Date(endDate), new Date()) < 24), // > 24j, timers doesn't work
                switchMap((endDate: string) => timer(new Date(endDate))),
                tap(() => {
                    this.document.defaultView?.location.reload();
                }),
                untilDestroyed(this),
            ).subscribe(() => {});
    }

    ngOnDestroy(): void {
        this.modalRef?.dispose();
    }

    ngAfterViewInit(): void {
        if (this.applicationFacade.getIsFreemiumOnboardingVisibleSnapshot()
            && this.authFacade.getUserSnapshot()?.sessionCount === 0
            && this.authFacade.getTokenSnapshot()
            && this.authorisationFacade.getPlanSnapshot()?.isFreemium
        ) {
            const userName = this.authFacade.getUserNameSnapshot();

            const resumeFeatureOperation = this.applicationFacade.getOperationSnapshot(UNLOCKED_FEATURE_OPERATION_ID);
            this.modalRef = this.modal.open(
                ModalOnboardingNewMemberComponent,
                {
                    resumeFeatureOperation,
                    userName,
                },
                {
                    padding: false,
                    hasBorder: true,
                    isOutsideClickCloseModal: false,
                },
            );

            this.modalRef.afterClosed$
                .pipe(
                    untilDestroyed(this),
                ).subscribe((value) => {
                    this.applicationFacade.updateIsFreemiumOnboardingVisible(false);
                    this.applicationFacade.deleteOperation(UNLOCKED_FEATURE_OPERATION_ID);

                    if (value === 'START_FREE_TRIAL') {
                        this.resumeFeatureService.freeTrial();
                    }
                });
        }
    }


    public async closeSideNav(): Promise<void> {
        const parent = this.router.url.split('/').filter((value) => !!value)?.[0];
        const parentPath = `/${parent || 'shares'}`;

        await this.router.navigate([parentPath, {
            outlets: {
                sideFull: null,
            },
        }], {
            relativeTo: this.activatedRoute,
            replaceUrl: true,
        });
    }

    public validPhoneNumber(count: number) {
        return this.modal.open(
            ValidPhoneNumberComponent,
            {
                sessionCount: count,
            },
            {
                padding: true,
                isOutsideClickCloseModal: count <= 2,
                closable: count < 3,
            },
        );
    }

    public signup() {
        if (this.applicationFacade.getOperationSnapshot(UNLOCKED_FEATURE_OPERATION_ID)) {
            this.applicationFacade.deleteOperation(UNLOCKED_FEATURE_OPERATION_ID);
        }
        void this.router.navigateByUrl('auth/signup');
    }

    public freetrial() {
        this.resumeFeatureService.openLockedFeatureModalByContext(ModalLockedFeatureMode.UPGRADE_TO_FREE_TRIAL, false);
    }

    public toggleMenu() {
        this.expanded = !this.expanded;
    }

    private openUrl(url: string | null) {
        if (url) this.document.defaultView?.open(url, '_blank');
    }
}
