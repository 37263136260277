import { HttpErrorResponse } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
} from '@angular/core';
import {
    FormControl,
    FormsModule,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import {
    createMask,
    InputMaskModule,
    InputmaskOptions,
} from '@ngneat/input-mask';
import { TranslocoPipe } from '@ngneat/transloco';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ValidPhonesService } from '~/app/core/services/api/valid-phones/valid-phones.service';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { AlertComponent } from '~/app/shared/components/alert/alert.component';
import { ButtonComponent } from '~/app/shared/components/button/button.component';
import { H2Component } from '~/app/shared/components/h2/h2.component';
import { IconComponent } from '~/app/shared/components/icon/icon.component';
import { LabelComponent } from '~/app/shared/components/label/label.component';
import { MaterialInputPhoneNumberComponent } from '~/app/shared/components/material-input-phone-number/material-input-phone-number.component';
import { ModalRef } from '~/app/shared/services/modal/modal-ref';

@UntilDestroy()
@Component({
    selector: 'eb-valid-phones-number',
    templateUrl: './valid-phone-number.component.html',
    styleUrls: ['./valid-phone-number.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        H2Component,
        LabelComponent,
        IconComponent,
        AlertComponent,
        MaterialInputPhoneNumberComponent,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        ButtonComponent,
        TranslocoPipe,
    ],
})
export class ValidPhoneNumberComponent {
    public smsSent: boolean = false;

    public loading: boolean = false;

    public maskInput: InputmaskOptions<unknown> | null = null;

    public sessionCount = this.ref.data.sessionCount;

    public error: boolean = false;

    public errorMsg: string = '';

    public fcPhoneNumber = new FormControl<string>(this.authenticationFacade.getUserSnapshot()?.phone ?? '', {
        nonNullable: true,
        validators: [Validators.required],
    });

    public fcCode = new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required],
    });

    public requestId: string = '';

    public success: boolean = false;

    constructor(
        private validPhonesService: ValidPhonesService,
        private authenticationFacade: AuthenticationFacade,
        private cd: ChangeDetectorRef,
        private ref: ModalRef<{}, {
            sessionCount: number,
        }>,
    ) {
        this.maskInput = createMask({
            mask: '9     9     9     9     9     9',
            placeholder: '',
            unmaskAsNumber: true,
            autoUnmask: true,
            showMaskOnFocus: true,
            showMaskOnHover: true,
            digits: 6,
        });
    }

    public close() {
        this.ref.close({});
    }

    public sendCode() {
        this.loading = true;
        this.error = false;
        this.validPhonesService.sendPhoneCode(this.fcPhoneNumber.value).pipe(
            catchError((error: HttpErrorResponse) => {
                this.loading = false;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                this.errorMsg = error.error?.message ?? error.error?.detail;
                this.error = true;
                this.cd.detectChanges();
                return EMPTY;
            }),
            untilDestroyed(this),
        ).subscribe((result) => {
            this.loading = false;
            this.smsSent = true;
            this.requestId = result.requestId;
            this.cd.detectChanges();
        });
    }

    public validCode() {
        this.loading = true;
        this.error = false;
        this.validPhonesService.validPhoneCode(this.fcPhoneNumber.value, this.requestId, this.fcCode.value).pipe(
            catchError((error: HttpErrorResponse) => {
                this.loading = false;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                this.errorMsg = error.error?.message ?? error.error?.detail;
                this.error = true;
                this.cd.detectChanges();
                return EMPTY;
            }),
            untilDestroyed(this),
        ).subscribe(() => {
            this.loading = false;
            this.success = true;
            const user = this.authenticationFacade.getUserSnapshot();
            if (user) {
                this.authenticationFacade.updateUser({
                    ...user,
                    phoneVerified: true,
                });
            }
            this.cd.detectChanges();
            setTimeout(() => {
                this.ref.close(true);
            }, 3000);
        });
    }

    public back() {
        this.smsSent = false;
    }
}
