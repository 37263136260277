import {
    Overlay,
    OverlayConfig,
    OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';

import { PlanModel } from '~/app/shared/types/api/authorisation/plan-model.type';
import { SidebarItemAuthorisation } from '~/app/shared/types/sidebar-item-authorisation.type';
import { SidebarItem } from '~/app/shared/types/sidebar-item.type';
import { User } from '~/app/shared/types/user/user.type';

import { AuthorisationDirective } from '../../directives/authorisation/authorisation.directive';
import { DropdownPanelAltComponent } from '../dropdown-panel-alt/dropdown-panel-alt.component';
import { DropdownPanelBodyComponent } from '../dropdown-panel-body/dropdown-panel-body.component';
import { IconComponent } from '../icon/icon.component';
import { LabelComponent } from '../label/label.component';
import { SidebarMenuItemComponent } from '../sidebar-menu-item/sidebar-menu-item.component';

@UntilDestroy()
@Component({
    selector: 'eb-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        IconComponent,
        SidebarMenuItemComponent,
        LabelComponent,
        AuthorisationDirective,
        DropdownPanelAltComponent,
        DropdownPanelBodyComponent,
        TranslocoPipe,
    ],
})
export class SidebarComponent {
    @Input()
    public user: User | null = null;

    @Input()
    public items: Array<SidebarItemAuthorisation> = [];

    @Input()
    public plan: PlanModel | null = null;

    @Input()
    public hasFreeTrial: boolean = false;

    @Input()
    public userItem!: SidebarItem;

    @Input()
    public mainItems: Array<SidebarItemAuthorisation> = [];

    @Input()
    public otherItems: Array<SidebarItemAuthorisation> = [];

    @Input()
    public hasRegistration: boolean = true;

    @HostBinding('class.expanded')
    @Input()
    public expanded: boolean = true;

    @ViewChild('subMenuTemplate') subMenuTemplate!: TemplateRef<HTMLElement>;

    @Output()
    public signup = new EventEmitter();

    @Output()
    public freetrial = new EventEmitter();

    @Output()
    public toggleMenu = new EventEmitter();

    public overlayRef?: OverlayRef;

    public currentSubMenuItems: SidebarItem[] = [];

    public constructor(
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
    ) {
    }

    public trackByItem(index: number, item: SidebarItem) {
        return item.key;
    }

    public clickItem(item: SidebarItem, menuItem: SidebarMenuItemComponent): void {
        if (item.action) {
            return item.action();
        }
        if (item.items && item.items.length > 0) {
            this.currentSubMenuItems = item.items;
            this.openOverlay(menuItem.elementRef);
        }

        return undefined;
    }

    public clickSubItem(item: SidebarItem) {
        if (item.action) {
            item.action();
        }
        this.overlayRef?.detach();
    }

    public planClick(plan: PlanModel) {
        if (plan.isFreemium) {
            this.freetrial.emit();
        }
    }

    private openOverlay(button: ElementRef<HTMLElement>) {
        const config = new OverlayConfig({
            hasBackdrop: true,
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(button)
                .setOrigin(button)
                .withPositions([
                    {
                        offsetX: 0,
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        offsetX: 0,
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                ])
                .withFlexibleDimensions(false)
                .withPush(false),
        });

        this.overlayRef = this.overlay.create(config);

        const portal = new TemplatePortal(this.subMenuTemplate, this.viewContainerRef);

        this.overlayRef.attach(portal);
        this.overlayRef.backdropClick()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.overlayRef?.detach();
            });
    }
}
