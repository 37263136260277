import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ValidPhonesService {
    constructor(private http: HttpClient) {}

    sendPhoneCode(phone: string) {
        return this.http.post<Readonly<{
            requestId: string,
            expiresAt: string,
        }>>('/members/me/send-phone-otp', { phone });
    }

    validPhoneCode(phone: string, requestId: string, code: string) {
        return this.http.post('/members/me/verify-phone-otp', {
            phone,
            requestId,
            code,
        });
    }
}
