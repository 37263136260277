<div class="main-layout__sidebar">
    <eb-sidebar
        [expanded]="expanded"
        [hasFreeTrial]="configurationFacade.hasFreeTrial$ | async"
        [hasRegistration]="configurationFacade.getHasRegistrationSnapshot()"
        [mainItems]="mainItems$ | async"
        [otherItems]="otherItems$ | async"
        [plan]="authorisationFacade.plan$ | async"
        [user]="authFacade.user$ | async"
        [userItem]="userItem$ | async"
        (freetrial)="freetrial()"
        (signup)="signup()"
        (toggleMenu)="toggleMenu()"
    />
</div>
<mat-sidenav-container
    class="main-layout__sidenav-container"
    [class.main-layout__sidenav-container--sidebar-reduced]="!expanded"
    [hasBackdrop]="true"
>
    <mat-sidenav-content>
        <div class="main-layout__content">
            <router-outlet />
        </div>
    </mat-sidenav-content>
    <mat-sidenav
        class="main-layout__sidenav"
        [disableClose]="false"
        [opened]="isSidebarOpen"
        (closedStart)="closeSideNav()"
        mode="over"
        position="end"
    >
        <div class="main-layout__sidenav-content">
            <router-outlet
                name="sideFull"
                (activate)="isSidebarOpen = true"
                (deactivate)="isSidebarOpen = false"
            />
        </div>
    </mat-sidenav>
</mat-sidenav-container>
