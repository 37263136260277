@if (icon) {
    <div class="sidebar-menu-item__icon">
        <eb-icon [icon]="icon" />
        @if ((notifications || 0) > 0) {
            <eb-badge
                class="sidebar-menu-item__icon-badge"
                [count]="notifications || 0"
            />
        }
    </div>
}
@if (expanded) {
    <eb-label
        class="sidebar-menu-item__label"
        size="md"
        textTransform="uppercase"
    >
        {{ label }}
    </eb-label>
    <eb-label
        class="sidebar-menu-item__description"
        size="xs"
        textTransform="uppercase"
    >
        {{ description }}
    </eb-label>
}
