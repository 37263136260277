<div class="sidebar__header">
    <div class="sidebar__logo">
        <div class="sidebar__logo-icon"></div>
        <div class="sidebar__logo-text"></div>
    </div>
    <button
        class="sidebar__expand-button"
        (click)="toggleMenu.emit()"
    >
        <eb-icon
            class="sidebar__expand-button-icon"
            [icon]="expanded ? 'ui/nav/close_menu' : 'ui/nav/open_menu'"
            width="32"
        />
    </button>
</div>

@if (!user && expanded) {
    <div class="d-flex">
        @if (hasRegistration) {
            <button
                class="sidebar__top-button"
                (click)="signup.emit()"
            >
                {{ 'common.sidebar.signup' | transloco }}
            </button>
        }
    </div>
}

@if (user && expanded && hasFreeTrial && plan && plan?.isFreemium && !user?.freeTrialUsed) {
    <div class="d-flex">
        <button
            class="sidebar__top-button"
            (click)="planClick(plan)"
            >{{ 'common.sidebar.test_premium' | transloco }}</button
        >
    </div>
}

@if (userItem) {
    <div class="sidebar__user">
        <eb-sidebar-menu-item
            #buttonRefUser
            [description]="userItem.description"
            [disabled]="userItem.disabled"
            [expanded]="expanded"
            [icon]="userItem.icon"
            [label]="userItem.label"
            [selected]="userItem.selected"
            (click)="clickItem(userItem, buttonRefUser)"
        />
    </div>
}

@if (user && expanded && plan) {
    <div class="d-flex">
        <eb-label
            class="sidebar__tag"
            (click)="hasFreeTrial && planClick(plan)"
            size="sm"
            >{{ plan.name }}</eb-label
        >
    </div>
}

<div class="sidebar__middle">
    @for (item of mainItems; track trackByItem($index, item)) {
        <eb-sidebar-menu-item
            ebAuthorisation
            [authorisationPermissions]="item.permissions"
            [authorisationResume]="item.resumeFeature"
            [customClass]="['unauthorised__main-menu']"
            [description]="item.description"
            [disabled]="item.disabled"
            [expanded]="expanded"
            [icon]="item.icon"
            [label]="item.label"
            [notifications]="item.notifications"
            [selected]="item.selected"
            (protectedClick)="clickItem(item, buttonRef)"
        />
    }
</div>

<!-- TODO: Fixer réelement le problème de la référence -->
<div #buttonRef></div>

@if (expanded) {
    <div class="sidebar__bottom">
        @for (item of otherItems; track trackByItem($index, item)) {
            <eb-sidebar-menu-item
                #buttonRef
                [description]="item.description"
                [disabled]="item.disabled"
                [expanded]="expanded"
                [icon]="item.icon"
                [label]="item.label"
                [notifications]="item.notifications"
                [selected]="item.selected"
                (click)="clickItem(item, buttonRef)"
            />
        }
    </div>
}

<ng-template
    #subMenuTemplate
    let-items="items"
>
    <eb-dropdown-panel-alt
        [radius]="true"
        [withBorder]="false"
        [withNotch]="false"
    >
        <eb-dropdown-panel-body>
            <div class="dropdown dropdown--bg-black">
                @for (subMenuItem of currentSubMenuItems; track trackByItem($index, subMenuItem)) {
                    <div
                        class="dropdown__item dropdown__item--border-black"
                        (click)="clickSubItem(subMenuItem)"
                    >
                        {{ subMenuItem.label }}
                    </div>
                }
            </div>
        </eb-dropdown-panel-body>
    </eb-dropdown-panel-alt>
</ng-template>
